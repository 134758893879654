import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/Layout'
import { systemFontStack } from '../utils/typography'
import { Container } from '../components/Scaffold'

const Article = styled.article`
  margin: 0 auto;
`

const Title = styled.h1`
  margin-bottom: 32px;

  @media (min-width: 768px) {
    margin-bottom: 48px;
  }
`

const Tag = styled.span`
  background-color: #f1f1f1;
  border-radius: 2px;
  color: #999;
  display: inline-block;
  font-family: ${systemFontStack.join(',')};
  font-size: 14px;
  height: 24px;
  line-height: 24px;
  padding: 0 8px;
  text-transform: capitalize;

  &:not(:first-child) {
    margin-left: 8px;
  }
`

export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout title={post.frontmatter.title + ' - Faith Howley'}>
      <Container maxWidth={640}>
        <Article>
          <header>
            <Title>{post.frontmatter.title}</Title>
          </header>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
          <div>
            {post.frontmatter.tags.map(tag => {
              if (!tag.length) {
                return null
              }
							return <Tag key={'tag-'+tag}>{tag}</Tag>
            })}
          </div>
        </Article>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        tags
      }
    }
  }
`
